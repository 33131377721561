import { Container, Text, Grid, BoxProps, GridItem, useMediaQuery } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import { FC } from 'react'

import type { FrontSearchProjectType } from '@miimosa/common/types'

import { ProjectCard } from '@components'

interface Props {
  projects: FrontSearchProjectType[]
}

const ProjectsGrid: FC<Props & BoxProps> = ({ projects }) => {
  const { t: tCommon } = useTranslation()
  const [isAbove1440] = useMediaQuery('(min-width: 1440px)')

  if (!projects.length)
    return (
      <Container variant="full" bgColor="creamy">
        <Container variant="boxed" px={{ base: 10, md: '60px' }} py="8">
          <Text textAlign="center" size="xl" my="5">
            {tCommon('no_projects_results')}
          </Text>
        </Container>
      </Container>
    )

  return (
    <Container variant="full" bgColor="creamy">
      <Container
        variant="boxed"
        minW={{ base: '100%', md: 'min(95%, 1440px)' }}
        maxW={{ base: '100%', md: 'min(95%, 1440px)' }}
      >
        <Grid
          width="100%"
          templateRows={{ base: '1fr', md: 'repeat(2, 1fr)' }}
          templateColumns={{
            base: '1fr',
            md: 'repeat(2, 1fr)',
            lg: 'repeat(3, 1fr)',
            xl: isAbove1440 ? 'repeat(4, 1fr)' : 'repeat(3, 1fr)',
          }}
          rowGap={4}
          justifyItems="center"
        >
          {projects?.map((project, index) => {
            // do not use ValidCollectType because you need undefined to not show story...

            return (
              <GridItem key={index} w={{ base: '100%', md: 'auto' }}>
                <ProjectCard
                  imageFileName={project.imageFileName}
                  interestsRatio={project.collect?.interestsRatio || 0}
                  slug={project.slug}
                  title={project.title}
                  teasing={project.teasing}
                  goalAmount={project.collect?.goalAmount || 0}
                  place={project.place}
                  endsAt={project.collect?.endsAt}
                  goalType={project.collect?.goalType || 'amount'}
                  collectType={project.collect?.type || 'donation'}
                  collectedAmount={project.collect?.collectedAmount || 0}
                  userFullName={project.userFullName}
                  avatarFileName={project.avatarFileName}
                  progress={project.collect?.progress || 0}
                  state={project.state}
                  labels={project.labels}
                  goalQuantity={project.collect?.goalQuantity || 0}
                  collectedQuantity={project.collect?.collectedQuantity || 0}
                />
              </GridItem>
            )
          })}
        </Grid>
      </Container>
    </Container>
  )
}

export default ProjectsGrid
